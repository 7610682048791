@import "~/src/assets/style/variables";

.titleContainer {
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: space-between;
}

.title {
  display: flex;
  align-items: center;

  > *:not(:first-child) {
    margin-left: 10px;
  }

  margin-top: 10px;
  margin-bottom: 4px;
}

.headerText {
  h5 {
    font-weight: $ds-typography-fontWeights-regular;
    margin-bottom: 0 !important;
  }

  span {
    line-height: 16px;
    vertical-align: middle;

    svg {
      margin-top: 2px;
    }
  }
}

.alertContainer {
  margin-bottom: 10px;
}
