.container {
  padding: 0 24px;
}

.loaderContainer {
  display: flex;
  flex: 1;
  height: 50vh;
  align-items: center;
  justify-content: center;
}

.contentContainer {
  margin-top: 16px;
}

.text {
  width: 100%;
  margin-top: 20px;
  margin-bottom: 20px;
  flex: none;
  order: 1;
  align-self: stretch;
  flex-grow: 0;
}

.recentFileContainer {
  cursor: pointer;
  flex-direction: column;
  margin-top: 10px;
}

.title {
  margin-bottom: 16px;
}

.assetsContainer {
  margin-top: 30px;
}

.fileNameContainer {
  display: flex;
  flex-direction: row;
  width: 100%;
  margin-bottom: 4px;
  overflow: hidden;
  word-break: break-all;

  > div {
    margin-right: 6px;
    width: 13px;
    height: 13px;
  }

  span {
    width: 100%;
    white-space: pre-wrap;
    word-wrap: break-word;
  }
}
