@import "~/src/assets/style/variables";
.unit * {
  color: $ds-colors-content-secondary;
}
.form {
  display: flex;
  flex-direction: column;
  gap: $ds-spacing-space_xs;
  margin-top: $ds-spacing-space_xs ;

  .item {
    display: flex;
    align-items: center;
  }
}

