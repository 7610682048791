@import "~/src/assets/style/variables";

.container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid #e6e6e8;
  padding: 16px;

  .leftContainer {
    display: flex;
    align-items: center;

    .closeButton {
      background: #f0f1f4;
      border-radius: 8px;
      width: 40px;
      height: 40px;
      cursor: pointer;
      display: flex;
      align-items: center;
      justify-content: center;
      margin-right: 16px;
    }

    .nameAndTemplate {
      display: flex;
      flex-direction: column;
      width: 340px;

      > *:not(:first-child) {
        margin-top: 4px;
      }
    }

    .nameAndState {
      display: flex;
      align-items: center;

      > span:first-of-type, input {
        font-family: $ds-typography-fontFamily-primary;
        font-size: $ds-typography-fontSizes-P2;
        line-height: $ds-typography-lineHeights-P2;
        font-weight:$ds-typography-fontWeights-medium;
        margin: 0;
        height: 20px;
        border:0;
        outline: 0;
        width: fit-content;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        margin-left: -2px;
      }

      :global(.ant-badge-status-text) {
        opacity: 0.65;
      }
    }

    .nameSizePlaceholder {
      white-space: pre;
      position: absolute;
      visibility: hidden;
      max-width: 240px;
    }

    .templateNameContainer {
      display: flex;
      align-items: center;
      gap : 5px;
      a {
        cursor: pointer;
      }
    }
  }
}
