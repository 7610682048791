.errorContainer {
  padding: 30px;
  text-align: left;
  position: absolute;
  top : 50px;
  height: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  backdrop-filter: brightness(190%) blur(20px);
  z-index: 1;
  .buttons {
    margin-top: 20px;
  }
}

.iframe {
  position: relative;
  width: 100%;
  flex : 1;
  border: none;
}
.hidden {
  visibility: hidden;
}
