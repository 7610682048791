.pickerContainer {
  display: flex;
  align-items: center;
  flex-direction: column;
}

.text {
  text-align: center;
  margin-bottom: 0;
}
