@import "variables.scss";

html,
body,
#root {
  height: 100%;
  font-family: $ds-typography-fontFamily-primary, "Helvetica Neue", "Helvetica", "Arial", sans-serif;
}

::-webkit-scrollbar {
  width: 5px;
}

::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
}

::-webkit-scrollbar-thumb {
  border-radius: 10px;
  background: $violet;
}
.gutter {
  z-index: 2;
}
.gutter:hover {
  cursor: col-resize;
}

a,
a:hover {
  color: $ds-colors-secondary-default;
}

//Ant override to make dropdown scrollable

.ant-dropdown-menu {
  max-height: 400px;
  overflow: auto;
}

// OVERRIDING ANTD STYLE
.ant-upload-wrapper {
  .ant-upload-list {
    max-height: 300px;
    overflow-y: scroll;
    padding-right: 10px;
  }
}