.container {
  min-height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.panelsContainer {
  position: relative;
  flex: 1;
  width: 100%;
  height: 100%;

  .split {
    overflow: hidden;
    position: absolute;
    top: 0;
    width: 100%;
    height: 100%;
    display: flex;
  }

  .leftPanel,
  .centerPanel,
  .rightPanel {
    position: relative;
  }

  .leftPanel,
  .centerPanel {
    overflow-y: scroll;
  }

  .centerPanel,
  .rightPanel{
    padding: 16px 16px 16px 8px;
  }


}
