@import "~/src/assets/style/variables";

.iframe {
  width: 100%;
  height : 100%;
  border : none;
}
.deviceContainer {
  background-color: #eff0f0;
  border: 2px solid #fff;
  border-radius: 20px;
  box-shadow: 0 10px 13px -7px #000, 5px 5px 15px 5px transparent;
  padding: 10px;
  position: relative;
  height:541px;
  width: 324px;
}