.container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 8px 0;

  .text {
    text-align: left;
    overflow: hidden;
    flex-grow: 1;
    white-space: nowrap;
    text-overflow: ellipsis;
  }

  .size {
    width: 100px;
    text-align: right;
  }

  .image {
    height: 24px;
    margin-right: 8px;
    object-fit: contain;
    width: 24px;
  }
}
