.containerLoader {
  margin-top: 35px;
  display: flex;
  justify-content: center;
}
.containerBody{
  margin-top: 15px;
}
.chartContainer {
  margin-top: 35px;
}
.alertContainer {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.popoverImageContainer {
  height: 250px;
  width: 250px;
  object-fit: contain;
}
.dimensionSelector {
  width: 100%;
}
.settings {
  margin-top: 5px;
  cursor: pointer;
}
