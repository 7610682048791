.pickerContainer {
  display: flex;
  align-items: center;
  gap : 10px;
  flex-direction: column;
}
.lottieFilesContainer {
  display: flex;
  align-items: center;
  gap : 8px;
  cursor: pointer;
}
.text {
  text-align: center;
  margin-bottom: 0;
}
