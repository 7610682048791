.container {
  display: flex;
  flex-direction: column;
  padding: 0 16px;
}

.iconCell {
  display: flex;
  flex-direction: column;
  justify-content: center;
}