@import "~/src/assets/style/variables";

.sliderContainer {
  display: flex;
  flex-grow: 1;
  align-items: center;
  margin-right: 16px;
}

.minRange {
  margin-right: $ds-spacing-space_xs;
  flex-shrink: 0;
}

.maxRange {
  margin-left: $ds-spacing-space_xs;
  flex-shrink: 0;
}