.container {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 470px;
  width: 470px;
  .imageContainer {
    position: absolute;
    height: 210px;
    width: 210px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .otherContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 10px;
  }
}
