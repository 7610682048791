.popoverImage {
  height: 210px;
  width: 210px;
  &.large {
    height: 280px !important;
    width: 280px !important;
  }
  object-fit: contain;
}
.container {
  position: relative;
}
.buttonsContainer {
  position: absolute;
  display: flex;
  gap : 10px;
  align-items: center;
  justify-content: center;
  width: 100%;
  transition: opacity 300ms;
  bottom: 10px;
}
