@import "~/src/assets/style/shared";

.canvasWrapper {
  background-color: #F2F2F2;
  flex-grow: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: $ds-spacing-space_s 0;

  &:global(.checkboard) {
    @extend .bg-checkboard-large;
    background-color: transparent;
  }
  
}