@import "~/src/assets/style/variables";

.fileUploadMetaWrapper {
  color: $ds-colors-content-primary;

  :global(.ant-form-item-has-error) .fileName {
    color: $ds-colors-destructive-content;
  }

  :global {
    .ant-form-item-control-input {
      min-height: auto;
    }
  }
}

.fileName {
  display: block;
  margin-top: -2px;
}

.fileIconWrapper {
  width: 32px;
  height: 32px;
  border-radius: $ds-shapes-rounded_s;
  background-color: $ds-colors-media-gray-gray1;
  display: flex;
  align-items: center;
  justify-content: center;
}

.fileIcon {
  width: 16px;
  height: 16px;
}

.iconCell {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.fileDescription {
  line-height: $ds-typography-lineHeights-P3;

  :global {
    span {
      color: $ds-colors-content-secondary;
    }
  }
}

.actionCell {
  display: flex;
  align-items: center;
}

.progressBarWrapper {
  margin-top: -6px;
}
