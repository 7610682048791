@import "~/src/assets/style/variables";

.loaderContainer {
  text-align: center;
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.resultContainer {
  display: flex;
  flex-flow: row wrap;
  justify-content: flex-start;

  .resultItem {
    height: 200px;
    cursor: pointer;
    display: flex;
    align-items: center;
    flex-direction: column;
    flex-basis: 20%;
    -ms-flex: auto;
    width: 250px;
    position: relative;
    padding: 10px;
    box-sizing: border-box;
    transition: all 200ms;
    &.selected {
      border: solid 2px $ds-colors-content-primary;
      border-radius: 5px;
      .chooseButton {
        bottom: 5px;
        position: absolute;
      }
    }
    &:hover {
      scale: 1.1;
    }
    video {
      width: 100%;
      height: auto;
      max-width: 220px;
      max-height: 180px;
    }
  }

}
.paginationContainer {
  text-align: center;

}
