
.iframeAndLanguageContainer {
  display: flex;
  flex-direction: column;
  height: 100%;
  .languageContainer {
    display: flex;
    justify-content: center;
    margin: 10px 0;
    gap : 10px;
  }
  .renderContainer {
    height: 100%;
    display: flex;
    flex-direction: column;
    text-align: center;
  }
}
.oguryPreviewContainer {
  display: flex;
  align-items: center;
  justify-content: center;
}
.overlayContainer {
  padding: 30px;
  text-align: left;
  position: absolute;
  height: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  backdrop-filter: brightness(190%) blur(20px);
  z-index: 1;
  .buttons {
    margin-top: 20px;
  }
}