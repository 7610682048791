.container {
  height: 100%;
  display: flex;
  flex-direction: column;
}

.detachedHeader {
  text-align: right;
  text-align: -webkit-right;
}

.childrenContainer {
  // padding: 16px;
  margin-bottom: 20px;
  display: flex;
  flex-direction: column;
  flex: 1;
  // background-color: #f9f9fa;
  // border-radius: 4px;
}

.detachedContainer {
  position: fixed;

  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 99;
  background: #fff;

  > div {
    padding: 10px;
  }
}
