
@import "~/src/assets/style/variables";

.radioContent {
  display: inline-flex;
}

.comment {
  margin-left: $ds-spacing-space_xs;
}
