.container {
  display: flex;
  flex-direction: column;
}

.loadingOverlay {
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  top: 0;
  left: 0;
  width: 100%;
  min-height: 100%;
  height: 100%;
  background: rgba(255, 255, 255, 0.8);
  z-index: 2;
}

.spinner {
  font-size: 32px;
}

.image {
  width: 100%;
  display: block;
  margin: auto;
}

.textContainer {
  margin-top: 20px;
}
