@import "~/src/assets/style/variables";

.container {
  position: relative;
}
.infos {
  display: flex;
  justify-content: space-between;
  margin-top: 10px;
  margin-bottom: 20px;
}

.urlPreview {
  font-size: 12px;
}

.cancelEditionButtonContainer {
  text-align: right;
  margin-bottom: 10px;
}

.actions {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap: $ds-spacing-space_xs;

  padding: 8px 0;
}

.pasteDropdownContainer {
  width: 250px;
  background: #FFF;
  border-radius: 8px;
  border: solid 1px $ds-colors-shape-neutral200;
  box-shadow: none;
  .pasteDropdownBody {
    .pasteDropdownItem {
      padding : 4px 8px;
    }
    padding: 8px;
  }
  .pasteDropdownFooter {
    background: $ds-colors-shape-neutral100;
    display: flex;
    border-bottom-left-radius: 8px;
    border-bottom-right-radius: 8px;
    justify-content: space-between;
    padding: 8px;
  }
}