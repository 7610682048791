@import "src/assets/style/variables";

.container {
  display: flex;
  flex-direction: row;
  align-items: center;
  cursor: pointer;
  padding: 0 16px 0 10px;
  height: 64px;

  border-left: solid 3px transparent;

  &:not(.active):not(.hidden) {
    border-bottom: solid 1px $grey-1;
  }

  transition: all 300ms, border 0ms;
  opacity: 1;
  visibility: visible;

  .left {
    display: flex;
    flex-direction: row;
    align-items: center;
    flex-grow: 1;
  }

  .right {
    display: flex;
    align-items: center;
  }
}

.child1 {
  padding: 4px 16px 4px 16px;
  height: 40px;
}

.child2 {
  padding: 4px 16px 4px 16px;
  height: 32px;
}

.hidden {
  opacity: 0;
  height: 0;
  padding: 0;
  visibility: hidden;
}

.active {
  background: #f0f1f4;
  border-left: solid 3px #7156f3;
}

.iconCarretContainer {
  padding: 5px 5px 5px 0;
  display: flex;
  position: relative;
}

.iconCarretEventHandler {
  position: absolute;
  top: 50%;
  right: 60%;
  transform: translate(50%, -50%);
  height: 24px;
  width: 24px;
}

.errorBadgeContainer {
  margin-left: 10px;
}

.valueLabel {
  margin-left: auto;
}
