.container {
  display: flex;
  align-items: center;
  position: relative;
  gap: 8px;
  justify-content: flex-end;
}

.dragOver {
  position: absolute;
  top: -49px;
  width: 290px;
  transform: scale(0.65);
  right: -60px;
  background: rgba(255, 255, 255, 0.82);
  z-index: 2;
}
