@import "variables";

.bg-checkboard {
  background-image: linear-gradient(45deg, $bg-checkboard 25%, transparent 25%, transparent 75%, $bg-checkboard 75%),
    linear-gradient(45deg, $bg-checkboard 25%, transparent 25%, transparent 75%, $bg-checkboard 75%);
  background-size: 24px 24px;
  background-position: 0 0, 12px 12px;
}

.bg-checkboard-large {
  @extend .bg-checkboard;
  background-size: 40px 40px;
  background-position: 0 0, 20px 20px;
}
