@import "~/src/assets/style/variables";

.inputTabularWrapper {
  color: $ds-colors-content-secondary;
}

.header {
  color: $ds-colors-content-secondary;
  margin-bottom: $ds-spacing-space_xxs;
}

.description {
  margin-bottom: $ds-spacing-space_s;
}

.googleSpreadsheetHelp {
  color: $ds-colors-content-secondary;
}

.sharedGoogleDriveFolderLink {
  display: inline-flex;
}
