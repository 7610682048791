.link {
  display: flex;
  align-items: center;
  gap: 2px;
}

.icon {
  width: 16px;
  height: 16px;
}
